import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  SecurityScoreIcon,
  useTranslations,
  getTimeFromNow,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
  Typography,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountStatus,
  EmployeeApplicationInfo,
} from '@uniqkey-backend-organization-web/api-client';
import { isNumber } from 'lodash';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import {
  EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS,
  EMPLOYEE_ACCOUNT_STATUS_COLORS,
} from '../../../constants';
import {
  TGetEmployeeAccountsByApplicationIdMethod,
} from '../../../hooks/useEmployeeAccountsAPI/interfaces';
import { parseSecurityScore } from '../../../helpers/employee';

export interface IServiceEmployeesTableRow extends EmployeeApplicationInfo {
  lastActivity: string | null;
}

interface IServiceEmployeesTableProps extends
  Omit<
    IUseTableReturn<IServiceEmployeesTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Omit<
    IUseQueryTableFetchReturn<TGetEmployeeAccountsByApplicationIdMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  employees: IUseQueryTableFetchReturn<TGetEmployeeAccountsByApplicationIdMethod>['data'];
  selectedEmployees: IUseTableReturn<IServiceEmployeesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IServiceEmployeesTableRow> = {
  selection: false,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const ServiceEmployeesTable = (props: IServiceEmployeesTableProps) => {
  const {
    employees: preEmployees,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    onColumnOrderByChange,
    onColumnOrderDirectionChange,
    selectedEmployees,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const employees = useMemo(() => preEmployees.map((employee) => ({
    ...employee,
    lastActivity: getTimeFromNow({
      date: employee.activityAt,
      locale: currentLanguage,
    }),
  })), [preEmployees, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('servicePage.status'),
      field: 'employeeAccountStatus',
      render: (rowData) => {
        let title;
        const { employeeAccountStatus } = rowData;
        if (employeeAccountStatus === EmployeeAccountStatus.Unprocessed) {
          title = t('common.openMobileApp');
        }
        if (employeeAccountStatus === EmployeeAccountStatus.ExistingUnprocessed) {
          title = t('employeesPage.existingUnprocessedTooltip');
        }
        if (title) {
          return (
            <TypographyWithTooltip
              variant="body2"
              color={EMPLOYEE_ACCOUNT_STATUS_COLORS[employeeAccountStatus]}
              title={title}
            >
              {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[employeeAccountStatus])}
            </TypographyWithTooltip>
          );
        }
        return (
          <Typography
            variant="body2"
            color={EMPLOYEE_ACCOUNT_STATUS_COLORS[employeeAccountStatus]}
          >
            {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[employeeAccountStatus])}
          </Typography>
        );
      },
      width: '15%',
    },
    {
      title: t('servicePage.employee'),
      field: 'userName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.userName}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: t('servicePage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('servicePage.securityScore.columnTooltip')}
        >
          {t('servicePage.securityScore')}
        </TypographyWithTooltip>
      ),
      field: 'securityScore',
      render: (rowData) => (isNumber(rowData.securityScore)
        ? <SecurityScoreIcon percentage={parseSecurityScore(rowData.securityScore)} />
        : t('common.noScore')),
      width: '20%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('servicePage.lastActivity.columnTooltip')}
        >
          {t('servicePage.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
  ]), [createColumns, t]);

  const handleEmployeeRowClick = useCallback<
    NonNullable<IMaterialTableProps<IServiceEmployeesTableRow>['onRowClick']>
  >((event, employee) => {
    navigate(`${PageRouteEnum.Employees}/${employee!.employeeAccountId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={employees}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      onColumnOrderByChange={onColumnOrderByChange}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedEmployees}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleEmployeeRowClick}
      rowSelectByKey="employeeAccountId"
      rowHeight={51}
    />
  );
};

export default memo(ServiceEmployeesTable);
